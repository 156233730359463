import React from 'react';

const SuggestionItem = (props) => {
  return (
    <div>
      <h5>{props.suggestion}</h5>
    </div>
  )
};


export default SuggestionItem;


